
  import { defineComponent, onMounted } from 'vue';
  import { $drawer, openDrawer, closeDrawer } from '@/store/drawer';
  import { detectVendor } from '@/helpers/vendor';
  import { useStore } from 'effector-vue/composition';
  import Test from '@/views/Test.vue';

  export default defineComponent({
    components: {
      Test,
    },
    setup() {
      const drawerStore = useStore($drawer);
      const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.altKey && ev.code === 'KeyL') {
          if (!drawerStore.value.opened) {
            openDrawer('changeLayout');
          } else {
            closeDrawer();
          }

          ev.preventDefault();
          ev.cancelBubble = true;
        }
      };

      document.addEventListener('keydown', onKeyDown);

      onMounted(() => {
        detectVendor();
      });
    },
  });
