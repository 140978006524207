
  import { defineComponent, onMounted } from 'vue';
  // import {useStore} from 'effector-vue/composition';

  export default defineComponent({
    methods: {},
    emits: {},
    props: {},
    computed: {
      main() {
        return '';
      },
    },
    data() {
      return {
        name1: 'name111',
        name3: 'name333',
      };
    },
    setup() {
      onMounted(() => {
        console.log('mounted');
      });
      return {
        name2: 'name222',
      };
    },
  });
